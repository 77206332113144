<template>
  <AppMainContainer
    center-class="offer"
    :show-modal="isRequestModalShown"
  >
    <template #subheader>
      <PageHeader isAdmin />
    </template>
    <template #subheader>
      <div class="action-list-wrapper action-list">
        <template v-if="isFormEditable">
          <div
            v-if="!isEdit"
            class="action-list__action"
            @click="isEdit = true"
          >
            Редактировать
          </div>
          <template v-if="isEdit">
            <div
              class="action-list__action"
              @click="saveProfileInfo"
            >
              Сохранить
            </div>
            <div
              class="action-list__action"
              @click="cancelEditing"
            >
              Отменить
            </div>
          </template>
        </template>
        <div
          v-else
          class="action-list__action"
          @click="isRequestModalShown = true"
        >
          Изменить реквизиты
        </div>
      </div>
    </template>
    <template #center>
      <h1 class="mb-3">
        Реквизиты
      </h1>
      <form
        v-if="!loader && !loading && companyProfileInfoClone && user"
        class="company-profile"
        @submit.prevent
      >
        <div class="d-flex justify-content-between mb-2">
          <div :class="{'cursor-pointer': isFormEditable && isEdit}">
            <div
              v-if="!companyProfileInfoClone.logo && isEdit"
              class="company-profile__file-upload"
              @click="startLogoUpload"
            >
              <IconAttachFile class="mb-2" />
              <span>Загрузить логотип</span>
            </div>
            <div
              v-if="companyProfileInfoClone.logo && companyProfileInfoClone.logo.path"
              :style="logStyle"
              @click="startLogoUpload"
            />
            <!--            <img-->
            <!--              v-if="companyProfileInfoClone.logo && companyProfileInfoClone.logo.path"-->
            <!--              :src="companyProfileInfoClone.logo.path"-->
            <!--              class="company-profile__file-uploaded"-->
            <!--              @click="startLogoUpload"-->
            <!--            >-->
            <img
              v-if="!isEdit && (!companyProfileInfoClone.logo || !companyProfileInfoClone.logo.path)"
              src="@/assets/img/organization-logo.png"
              class="company-profile__file-uploaded"
              @click="startLogoUpload"
            >
            <input
              ref="logo"
              class="d-none"
              type="file"
              @change="updateLogo"
            >
          </div>
          <!--          <div class="action-list">-->
          <!--            <template v-if="isFormEditable">-->
          <!--              <div-->
          <!--                v-if="!isEdit"-->
          <!--                class="action-list__action"-->
          <!--                @click="isEdit = true"-->
          <!--              >-->
          <!--                Редактировать-->
          <!--              </div>-->
          <!--              <template v-if="isEdit">-->
          <!--                <div-->
          <!--                  class="action-list__action"-->
          <!--                  @click="saveProfileInfo"-->
          <!--                >-->
          <!--                  Сохранить-->
          <!--                </div>-->
          <!--                <div-->
          <!--                  class="action-list__action"-->
          <!--                  @click="cancelEditing"-->
          <!--                >-->
          <!--                  Отменить-->
          <!--                </div>-->
          <!--              </template>-->
          <!--            </template>-->
          <!--            <div-->
          <!--              v-else-->
          <!--              class="action-list__action"-->
          <!--              @click="isRequestModalShown = true"-->
          <!--            >-->
          <!--              Изменить реквизиты-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div class="border-bottom pb-3 mb-3">
          <AppFormGroup
            v-for="field in getFormFields"
            :key="field.key"
            v-model="companyProfileInfoClone[field.key]"
            :type="field.type || 'text'"
            :label="field.label"
            :required="field.required"
            :maxLength="FORM_MAX_LENGTH[field.key.toUpperCase()]"
            :disabled="!isFieldEditable(field.key)"
            :validationList="field.validator"
            :showValidationMessage="field.showValidationMessage"
          />
        </div>
        <div
          v-if="!isIp"
          class="border-bottom pb-3 mb-3"
        >
          <AppFormGroup
            v-model="companyHasDelegatedSignature"
            type="checkbox"
            label="Другой подписант в договоре"
            :disabled="!isFieldEditable()"
          />
          <template v-if="companyHasDelegatedSignature">
            <AppFormGroup
              v-for="field in organizationSignatoryAuthorityForm"
              :key="field.key"
              v-model="organizationSignatoryAuthority[field.key]"
              :label="field.label"
              :disabled="!isFieldEditable(field.key)"
            />
            <AppFormGroup
              v-model="organizationSignatoryAuthority.authority"
              :options="['Устав', 'Доверенность']"
              type="select"
              label="Основание полномочий"
              :disabled="!isFieldEditable()"
            />
            <template v-if="organizationSignatoryAuthority.authority === 'Доверенность'">
              <AppFormGroup
                v-for="field in organizationProcurationForm"
                :key="field.key"
                v-model="organizationSignatoryAuthority.procuration[field.key]"
                input-class="w-50"
                :type="field.type"
                :label="field.label"
                :disabled="!isFieldEditable(field.key)"
              />
            </template>
          </template>
        </div>
        <div class="pb-3 mb-3">
          <div style="margin: 0; position: relative">
            <AppFormGroup
              v-model="bikNumber"
              style="margin-bottom: 0"
              label="БИК"
              :required="true"
              :maxLength="FORM_MAX_LENGTH.BIK"
              :disabled="!isFieldEditable() || bikLoader"
              @input="getBankInfo"
            />
            <span
              v-if="bikError"
              style="
              display: inline-block;
              color: red;
              font-size: 0.8em;
              padding-left: 60px;
              margin: 0 auto 0 33%;
              position: absolute;
              bottom: -16px"
            >
              Данный БИК не найден, проверьте правильность.
            </span>
          </div>
          <template v-if="bankInfo">
            <AppFormGroup
              v-for="it in bankInfoForm"
              :key="it.label"
              v-model="bankInfo[it.key]"
              :label="it.label"
              disabled
            />
          </template>
          <AppFormGroup
            v-model="companyProfileInfoClone.checkingAccount"
            label="Расчетный счет"
            :required="true"
            :maxLength="FORM_MAX_LENGTH.CHECKING_ACCOUNT"
            :disabled="!isFieldEditable('checkingAccount')"
            :validationList="companyProfileInfoCloneValidParams[0]['validator']"
            :showValidationMessage="companyProfileInfoCloneValidParams[0]['showValidationMessage']"
          />
        </div>
        <div
          v-if="isEdit"
          class="action-list-wrapper"
        >
          <div
            class="action-list__action"
            @click="cancelEditing"
          >
            Отменить
          </div>
          <div
            class="action-list__action"
            @click="saveProfileInfo"
          >
            Сохранить
          </div>
        </div>
      </form>
      <AppLoader
        v-else
        class="full-height"
      />
    </template>
    <template #modal>
      <CompanyProfileModal
        v-if="isRequestModalShown"
        @close="isRequestModalShown = false"
      />
    </template>
  </AppMainContainer>
</template>

<script>
import clone from 'lodash.clonedeep'

import AppMainContainer from '@/components/common/layout/AppMainContainer'
import CompanyProfileModal from '@/components/companyProfile/CompanyProfileModal'
import IconAttachFile from '@/components/common/icons/IconAttachFile'
import AppFormGroup from '@/components/common/AppFormGroup'
import AppLoader from '@/components/AppLoader'
import { paymentNumber } from '@/utils/validation'
import routeList from '@/router/labels'

import { mapActions, mapState } from 'vuex'
import PageHeader from '@/components/subheaders/PageHeader.vue'
export default {
  name: 'PageCompanyProfile',
  components: {
    PageHeader,
    AppMainContainer,
    IconAttachFile,
    CompanyProfileModal,
    AppFormGroup,
    AppLoader
  },
  data: () => ({
    style: {
      'width': '100px',
      'height': '100px',
      'marginLeft': '16px',
      'border': 'thin grey',
    },
    isEdit: false,
    loading: false,
    serverValidationEmail: null,
    FORM_MAX_LENGTH: {
      BIK: 9,
      KPP: 9,
      CHECKING_ACCOUNT: 20,
    },
    file: null,
    bikError: false,
    companyProfileInfoClone: null,
    companyProfileInfoCloneValidParams: [
      {
        validator: [
          {
            text: 'Неверный формат расчетного счета ХХХХХХХХХXXXXXXXXXXX(20 цифр)',
            valid: true, validator: (value) => paymentNumber(value)
          },
        ],
        showValidationMessage: true
      }
    ],
    companyProfileInfoForm: [],
    // companyProfileInfoForm: [
    //   {
    //     label: 'Торговая Марка',
    //     key: 'tradeMark',
    //   },
    //   {
    //     label: 'Наименование Организации', required: true,
    //     key: 'title',
    //   },
    //   {
    //     label: this.isIp ? 'Регион*' : 'Юридический адрес', required: true,
    //     key: 'legalAddress',
    //   },
    //   {
    //     label: 'Фактический адрес', required: true,
    //     key: 'address',
    //     type: 'textarea',
    //   },
    //   // {
    //   //   label: 'Телефон',
    //   //   key: 'telephone',
    //   //   validator: [
    //   //     {
    //   //       text: 'Неверный формат телефона (79ХХХХХХХХХ)',
    //   //       valid: true, validator: (value) => ruPhoneValidator(value)
    //   //     },
    //   //   ],
    //   //   showValidationMessage: true
    //   // },
    //   {
    //     label: this.isIp ? 'Руководитель ИП*' : 'Генеральный директор', required: true,
    //     key: 'director',
    //   },
    //   {
    //     label: 'ИНН', required: true,
    //     key: 'inn',
    //   },
    //   {
    //     label: 'КПП', required: true,
    //     key: 'kpp',
    //     validator: [
    //       {
    //         text: 'Неверный формат КПП XXXXXXXXX',
    //         valid: true, validator: (value) => kppNumber(value)
    //       },
    //     ],
    //     showValidationMessage: true
    //   },
    // ],
    companyHasDelegatedSignature: false,
    organizationSignatoryAuthority: {
      fullName: null,
      profession: null,
      authority: null,
      procuration: {
        number: null,
        dateOfIssue: null,
        validUntil: null
      }
    },
    organizationSignatoryAuthorityForm: [
      {
        label: 'ФИО подписанта в договоре',
        key: 'fullName',
      },
      {
        label: 'Должность подписанта',
        key: 'profession',
      },
    ],
    organizationProcurationForm: [
      {
        label: 'Номер доверенности',
        key: 'number',
        type: 'text'
      },
      {
        label: 'Дата выдачи',
        key: 'dateOfIssue',
        type: 'date'
      },
      {
        label: 'Срок действия',
        key: 'validUntil',
        type: 'date'
      },
    ],
    fieldsNotAllowedForEditing: [
      'legalAddress',
      'title',
      'inn',
    ],
    isRequestModalShown: false,
    bikNumber: '',
    bikLoader: false,
    bankInfo: null,
    bankInfoForm: [
      {
        label: 'Название банка',
        key: 'title',
      },
      {
        label: 'Корреспондентский счет',
        key: 'correspondingAccount',
      },
    ],
  }),
  computed: {
    userOrganization () {
      return this.$store.state.users.info.organization
    },
    organizationEditable () {
      return this.userOrganization && !this.userOrganization.isActive
    },
    logStyle () {
      if (this.companyProfileInfoClone && this.companyProfileInfoClone.logo.path) {
        return {
          ...this.style,
          'backgroundImage': `url("${this.companyProfileInfoClone.logo.path}")`,
          'backgroundPosition': 'center',
          'backgroundSize': '100% auto',
          'backgroundRepeat': 'no-repeat',
        }
      }
      return {

      }
    },
    getFormFields () {
      const fieldList = [
        {
          label: 'Торговая Марка',
          key: 'tradeMark',
        },
        {
          label: 'Наименование Организации', required: true,
          key: 'title',
        },
        {
          label: 'Регион', required: true,
          key: 'legalAddress',
        },
        {
          label: 'Адрес по месту регистрации', required: true,
          key: 'address',
          type: 'textarea',
        },
        // {
        //   label: 'Телефон',
        //   key: 'telephone',
        //   validator: [
        //     {
        //       text: 'Неверный формат телефона (79ХХХХХХХХХ)',
        //       valid: true, validator: (value) => ruPhoneValidator(value)
        //     },
        //   ],
        //   showValidationMessage: true
        // },
        {
          label: this.isIp ? 'Руководитель ИП' : 'Генеральный директор', required: true,
          key: 'director',
        },
        {
          label: 'ИНН', required: true,
          key: 'inn',
        },
        {
          label: 'ОГРН',
          required: true,
          key: 'registrationNumber',
          // validator: [
          //   {
          //     text: 'Неверный формат КПП XXXXXXXXX',
          //     valid: true, validator: (value) => kppNumber(value)
          //   },
          // ],
          // showValidationMessage: true
        },
      ]

      if (!this.isIp) {
        return [
          {
            label: 'Торговая Марка',
            key: 'tradeMark',
          },
          {
            label: 'Наименование Организации', required: true,
            key: 'title',
          },
          {
            label: this.isIp ? 'Регион*' : 'Юридический адрес', required: true,
            key: 'legalAddress',
          },
          {
            label: 'Фактический адрес', required: true,
            key: 'address',
            type: 'textarea',
          },
          // {
          //   label: 'Телефон',
          //   key: 'telephone',
          //   validator: [
          //     {
          //       text: 'Неверный формат телефона (79ХХХХХХХХХ)',
          //       valid: true, validator: (value) => ruPhoneValidator(value)
          //     },
          //   ],
          //   showValidationMessage: true
          // },
          {
            label: this.isIp ? 'Руководитель ИП*' : 'Генеральный директор', required: true,
            key: 'director',
          },
          {
            label: 'ИНН', required: true,
            key: 'inn',
          },
          {
            label: 'КПП', required: true,
            key: 'kpp',
            // validator: [
            //   {
            //     text: 'Неверный формат КПП XXXXXXXXX',
            //     valid: true, validator: (value) => kppNumber(value)
            //   },
            // ],
            // showValidationMessage: true
          },
          {
            label: 'ОГРН',
            required: true,
            key: 'registrationNumber',
          },
        ]
      }

      return fieldList
    },
    isIp () {
      return this.companyProfileInfo.legalFormType && this.companyProfileInfo.legalFormType.alias === 'IP'
    },
    ...mapState('companyProfile', {
      companyProfileInfo: 'info',
      loader: 'loader',
    }),
    ...mapState('office', [
      'userOffice',
    ]),
    ...mapState('users', {
      user: 'info',
    }),
    params () {
      const params = {
        ...this.companyProfileInfoClone,
        organizationSignatoryAuthority: null,
        logo: null,
      }

      if (this.companyHasDelegatedSignature) {
        params.organizationSignatoryAuthority = this.organizationSignatoryAuthority

        if (params.organizationSignatoryAuthority.authority === 'Устав') {
          params.organizationSignatoryAuthority.procuration = {
            number: null,
            dateOfIssue: null,
            validUntil: null
          }
        }
      }

      if (this.companyProfileInfoClone.logo && this.companyProfileInfoClone.logo.id) {
        params.file = {
          id: this.companyProfileInfoClone.logo.id
        }
      }

      if (this.bankInfo) {
        params.bank = this.bankInfo
      }

      return params
    },
    isFormEditable () {
      // if (this.companyProfileInfoClone && this.companyProfileInfoClone.isActive) {
      //   return false
      // }
      //
      // if (!userUtil.isRoot(this.user)) {
      //   return false
      // }

      return this.organizationEditable
    },
    isFormValid () {
      const companyProfileValidationMap = this.getFormFields.filter(
        (item) => item.validator).map((item) => item.validator
      )
      const companyProfileCloneValidationMap = this.companyProfileInfoCloneValidParams.filter(
        (item) => item.validator).map((item) => item.validator
      )
      const allValidateFields = [...companyProfileValidationMap, ...companyProfileCloneValidationMap]
      const isValidFields = allValidateFields.filter((value) => value.every((el) => el.valid))

      return allValidateFields.length === isValidFields.length
    }
  },
  async created () {
    await this.fetchCompanyInfo()
    this.initProfileInfoForm()
  },
  methods: {
    ...mapActions({
      fetchCompanyInfo: 'companyProfile/fetchCompanyProfileInfo',
      updateCompanyInfo: 'companyProfile/updateCompanyProfileInfo',
      fetchBankInfo: 'companyProfile/fetchBankInfo'
    }),
    initProfileInfoForm () {
      this.companyProfileInfoClone = clone(this.companyProfileInfo)

      if (this.companyProfileInfoClone.organizationSignatoryAuthority) {
        this.organizationSignatoryAuthority = this.companyProfileInfoClone.organizationSignatoryAuthority
        this.companyHasDelegatedSignature = true
      }

      if (this.companyProfileInfoClone.bank) {
        this.bankInfo = this.companyProfileInfoClone.bank
        this.bikNumber = this.companyProfileInfoClone.bank.bik
      }
    },
    async saveProfileInfo () {
      if (!this.isFormValid) {
        return
      }
      try {
        this.loading = true
        // if (this.file) {
        //   await this.sendLogo()
        //   await this.updateCompanyInfo(this.params)
        //   this.isEdit = false
        //   return
        // }
        const { financialModel } = this.params
        await this.updateCompanyInfo({
          ...this.params,
          financialModel: financialModel ? financialModel.id : null
        })
        this.$store.dispatch('organization/fetchOrganizationUser').then((response) => {
          const data = response.data
          if (data && data.isActive) {
            this.$router.push({ name: routeList.HOME_ROUTE_NAME })
          } else {
            this.isEdit = false
            this.loading = false
          }
        })
      } catch (e) {
        console.log(e)
        this.loading = false
        this.isEdit = true
      }
    },
    cancelEditing () {
      this.initProfileInfoForm()
      this.isEdit = false
    },
    isFieldEditable (key) {
      if (this.fieldsNotAllowedForEditing.includes(key)) {
        return false
      }

      if (!this.isEdit) {
        return false
      }

      return true
    },
    startLogoUpload () {
      if (!this.isFormEditable || !this.isEdit) {
        return
      }
      this.$refs.logo.click()
    },
    async updateLogo () {
      this.file = this.$refs.logo.files[0]
      const reader = new FileReader()

      reader.onload = (event) => {
        this.companyProfileInfoClone.logo = {
          path: event.target.result
        }
      }

      reader.readAsDataURL(this.file)
      if (this.file) {
        await this.sendLogo()
      }
    },
    async sendLogo () {
      const { data } = await this.$store.dispatch('uploadClientFile', this.file)
      this.companyProfileInfoClone.file = {
        id: data.id
      }

      return Promise.resolve()
    },
    async getBankInfo () {
      this.bikError = false
      if (!String(this.bikNumber).match(/\d{9}/)) {
        this.bankInfo = null
        return
      }
      this.bikLoader = true
      try {
        const { data } = await this.fetchBankInfo({
          'search[title]': this.bikNumber
        })
        if (!data.length) {
          this.bikError = true
        }
        this.bankInfo = data[0]
      } finally {
        this.bikLoader = false
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.action-list-wrapper
  display: flex
  flex-direction: row
  justify-content: flex-end
  align-items: flex-end
.company-profile
  font-size: 14px
  line-height: 17px
  font-weight: 500

  &__file-upload
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100px
    height: 100px
    border: 1px dashed #000000
    border-radius: 5px
    text-align: center
    cursor: pointer

  &__file-uploaded
    object-fit: contain
    width: 100px
    height: 100px
    border: 1px dashed #000000
    border-radius: 5px

.action-list
  text-align: right
  &__action
    margin-bottom: 20px
    margin-right: 40px
    font-size: 16px
    line-height: 20px
    font-weight: 800
    opacity: 0.6
    cursor: pointer
</style>
