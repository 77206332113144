<template>
  <div class="row align-items-center mb-3 fw-600 ">
    <label
      :for="label"
      class="col-4 pr-5 text-dark-gray text-right"
    >
      {{ label }}
      <span
        v-if="required"
        style="color: red"
      >*</span>
    </label>
    <div class="col-8 pl-5">
      <div class="w-75">
        <input
          v-if="type === 'text'"
          :id="label"
          :value="value"
          :disabled="disabled"
          :placeholder="placeholder"
          class="app-input"
          :maxlength="maxLength"
          :class="inputClass"
          @input="$emit('input', $event.target.value)"
          @blur="isFocused = true"
          @keyup="keyUp"
          @change="onChangeEvent(value)"
        >
        <textarea
          v-if="type === 'textarea'"
          style="resize: none"
          :value="value"
          :placeholder="placeholder"
          rows="2"
          class="app-input"
          :disabled="disabled"
          :class="inputClass"
          @input="$emit('input', $event.target.value)"
          @blur="isFocused = true"
          @keyup="keyUp"
          @change="onChangeEvent(value)"
        />
        <label
          v-if="type === 'checkbox'"
          class="app-checkbox"
          :class="{ 'app-checkbox--checked': value }"
        >
          <input
            :id="label"
            type="checkbox"
            class="d-none"
            :value="value"
            :disabled="disabled"
            @change="$emit('input', !value)"
          >
        </label>
        <AppSelect
          v-if="type === 'select'"
          :disabled="disabled"
          :value="value"
          :visual-value="value"
          placeholder="Выберите тип"
          :values="options"
          @change="(val) => $emit('input', val)"
        />
        <template v-if="type === 'date'">
          <flat-pickr
            :id="label"
            ref="flatpickr"
            :value="value"
            :disabled="disabled"
            placeholder="Нет данных"
            class="app-input cursor-pointer"
            :class="inputClass"
            type="Timestamp"
            :config="flatpickrConfig"
            @input="(val) => $emit('input', val)"
          />
        </template>
      </div>
      <AppValidationGroupItems
        v-if="!isAllValid && showValidationMessage && isFocused"
        :showIcon="false"
        item-class="error-message-text"
        :validations-message="validationList"
      />
    </div>
  </div>
</template>

<script>
import AppValidationGroupItems from '@/components/common/AppValidationGroupItems'
import AppSelect from '@/components/common/simple/AppSelect'
import { Russian } from 'flatpickr/dist/l10n/ru'

export default {
  name: 'AppFormGroup',
  components: {
    AppSelect, AppValidationGroupItems
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Boolean],
    },
    options: {
      type: [Array]
    },
    placeholder: {
      type: String,
      default: 'Нет данных'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    inputClass: {
      type: String,
    },
    validationList: {
      type: Array
    },
    showValidationMessage: {
      type: Boolean
    },
    clearFocus: {
      type: Boolean
    },
    onValidEvent: {
      type: String,
      required: false
    }
  },
  data: () => ({
    flatpickrConfig: {
      locale: Russian,
      dateFormat: 'Z',
      altFormat: 'd.m.y',
      altInput: true
    },
    isFocused: false
  }),
  computed: {
    isAllValid () {
      const checkList = this.validationList ? !!this.validationList.length : false
      if (!checkList) return true
      return this.validationList.every((el) => el.valid)
    },
    serverValidationFind () {
      if (!this.serverValidation) return []
      return this.serverValidation.find((key) => key.propertyPath === 'email')
    }
  },
  watch: {
    value (newValue) {
      if (this.clearFocus !== undefined) {
        this.isFocused = this.clearFocus
      }
      if (!this.validationList) return
      this.validationList.forEach((item) => {
        if (item.validator instanceof Function) {
          item.valid = item.validator(newValue)
        }
      })
    }
  },
  created () {
    if (this.onValidEvent) {
      this.eventsBus.$on(this.onValidEvent, this.valid)
    }
  },
  methods: {
    keyUp ($event) {
      if (this.onKeyUp instanceof Function) {
        this.onKeyUp($event, this.name)
      }
    },
    inputValue ($event) {
      if (this.validationInput) {
        if (!this.validationInput($event.target.value)) {
          $event.target.value = $event.target.value.substring(0, $event.target.value.length - 1)
        }
        this.setValue($event.target.value)
      } else {
        this.setValue($event.target.value)
      }
    },
    mapLabel (label) {
      return label.match(/.[*]$/)
    },
    valid (value, checkOnly = true) {
      let isValid = true
      if (!this.validationList) return
      this.validationList.forEach((item) => {
        if (item.validator instanceof Function) {
          item.valid = item.validator(this.value)
          if (!item.valid) {
            isValid = false
          }
        }
      })
      if (isValid && !checkOnly) {
        this.setValue(value)
      }
    },
    onChangeEvent ($event) {
      if (this.onChange) {
        this.onChange($event)
      }
    }
  }
}
</script>

<style lang="sass">
.app-input
  display: flex
  align-self: baseline
  width: 100%
  border: 1.5px solid rgba(155, 155, 155, 0.3)
  border-radius: 25px
  padding: 9px 18px
  background-color: transparent
  transition: 0.3s all cubic-bezier(0.23, 1, 0.32, 1)

  &:disabled
    border: 1.5px solid rgba(155, 155, 155, 0)
    color: black

.app-checkbox
  position: relative
  display: flex
  align-items: center
  margin-left: 18px
  width: 25px
  height: 25px
  border-radius: 6px
  border: 1.5px solid rgba(0, 0, 0, 0.3)
  transition: .3s background-color
  cursor: pointer

  &--checked
    background: #8BD118
    border: 0

    &:after
      content: url(../../assets/img/ok_white.svg)
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
</style>
